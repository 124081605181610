/*.
  Ionic Variables and Theming. For more info, please see:
  http://ionicframework.com/docs/theming/
*/
:root {
  --ion-font-family: 'Poppins';

  //--cloudotdr-primary: #0B1416; //#020619;
  --cloudotdr-primary: #020619;
  --cloudotdr-primary-shade: #0B1416;
  --cloudotdr-primary-sup: rgb(129, 128, 128);
  --cloudotdr-secondary: #FB6543;

  --ion-background-color: var(--cloudotdr-primary-shade);
  --ion-card-background: var(--cloudotdr-primary-shade);

  --ion-color-primary-contrast: var(--cloudotdr-primary);
  --ion-color-primary: #ffffff;
  --button-color: #ffffff;
  --ion-color-primary-shade: var(--cloudotdr-primary);
  --ion-color-primary-tint: var(--cloudotdr-primary);

  //--ion-color-secondary: #04d974;
  --ion-color-secondary: var(--cloudotdr-secondary);
  --ion-color-secondary-rgb: 4,217,116;
  --ion-color-secondary-contrast: #FFF;
  --ion-color-secondary-contrast-rgb: 0,45,87;
  --ion-color-secondary-shade: var(--cloudotdr-secondary);
  --ion-color-secondary-tint: var(--cloudotdr-secondary);

  --ion-color-success: #7bdcb5;
  --ion-color-success-rgb: 4,217,116;
  --ion-color-success-contrast: #002d57;
  --ion-color-success-contrast-rgb: 0,45,87;
  --ion-color-success-shade: #7bdcb5;
  --ion-color-success-tint: #7bdcb5;

  //--ion-color-tertiary: #283459;
  //--ion-color-tertiary-rgb: 4,217,116;
  //--ion-color-tertiary-contrast: #002d57;
  //--ion-color-tertiary-contrast-rgb: 0,45,87;
  //--ion-color-tertiary-shade: #7bdcb5;
  //--ion-color-tertiary-tint: #7bdcb5;

  --ion-color-danger: #FB6542;
  --ion-color-danger-rgb: 251,101,66;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #dd593a;
  --ion-color-danger-tint: #fb7455;

  --ion-overlay-background-color: var(--cloudotdr-primary);

  --ion-text-color: #fff; // Text color of the entire app

  //--otdr-marker-a: #FFBB00;
  //--otdr-marker-b: #FB6542;
  //--otdr-marker-ref: #92949c;
  //--otdr-marker-ref-contrast: #ffffff;
  --ion-safe-area-bottom: 14px;
}

.ios {
  --ion-safe-area-top: -1 !important;
  --ion-safe-area-bottom: 14px;
}

.single-select-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 400px){
  .single-select-value {
    max-width: 200px !important;
  }
}

@media (min-width: 401px) and (max-width: 600px){
  .single-select-value {
    max-width: 300px !important;
  }
}

.single-select-header {
  white-space: nowrap;
  overflow: hidden;
  min-width: 200px !important;
}

.ods-spinner-small {
  svg {
    width: 30px;
    height: 30px;
  }
}

.ods-spinner-medium {
  svg {
    width: 50px;
    height: 50px;
  }
}

.ods-spinner-large {
  svg {
    width: 100px;
    height: 100px;
  }
}
