/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "../../../node_modules/@ionic/angular/css/core.css";
@import '@ionic/angular/css/palettes/dark.always.css';

/* Basic CSS for apps built with Ionic */
@import "../../../node_modules/@ionic/angular/css/normalize.css";
@import "../../../node_modules/@ionic/angular/css/structure.css";
@import "../../../node_modules/@ionic/angular/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "../../../node_modules/@ionic/angular/css/padding.css";
@import "../../../node_modules/@ionic/angular/css/float-elements.css";
@import "../../../node_modules/@ionic/angular/css/text-alignment.css";
@import "../../../node_modules/@ionic/angular/css/text-transformation.css";
@import "../../../node_modules/@ionic/angular/css/flex-utils.css";
@import '../../../node_modules/@ionic/angular/css/display.css';

@import 'theme/fonts';
@import 'theme/variables';
@import 'theme/scanReport';
@import 'theme/hierarchical';

body {
  --background: var(--cloudotdr-primary) !important;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.ion-page {
  //--ion-safe-area-top: 0;
  margin: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left) !important;
}

ion-modal ion-header {
  --background: var(--cloudotdr-primary-shade) !important;
}

ion-tabs {
  ion-tab-bar {
    margin-top: 5px;
    padding-top: 5px;
  }
}

// Header
ion-toolbar {
  --background: var(--cloudotdr-primary) !important;
  --color: #fff !important;
  //max-height: 45px !important;
  height: 45 + env(safe-area-inset-top) px;
}

ion-tab-bar {
  --background: var(--cloudotdr-primary) !important;
  --color: #fff !important;
}

//ion-icon {
//  color: var(--ion-color-medium);
//}

ion-label {
  color: var(--ion-color-primary);
  //color: #fff;
  ion-icon {
    margin-bottom: -3px;
  }
}

ion-action-sheet {
  --backdrop-opacity: 0.6;
  --button-color: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary) !important;
}

ion-action-sheet.cloudotdr-action-sheet .action-sheet-button,
ion-action-sheet.cloudotdr-action-sheet .action-sheet-button.ion-focused {
  border-bottom: 1px solid var(--cloudotdr-primary-shade) !important;
}


ion-menu {
  ion-item-divider {
    min-height: 0px;
  }
}

.showAsLink {
  cursor: pointer;
}

.fullwidth {
  width: 100%;
}
.background-primary {
  background-color: var(--cloudotdr-background) !important;
}

.sup-header {
  color: var(--cloudotdr-primary-sup) !important;
}

.group-header {
  color: var(--cloudotdr-secondary) !important;
  font-weight: bold;
}

.showAsError {
  color: var(--cloudotdr-secondary) !important;
  font-weight: bold;
}

.select-link-option {
  span {
    padding: 2px 2px !important;
  }
  .activeLink {
    //color: var(--ion-color-secondary);
    font-weight: bold !important;
  }
}

.select-full-width {
  max-width: 100% !important;
  width: 100% !important;
  padding-left: 0 !important;
}

ion-tab-button {
  ion-icon {
    font-size: 25px !important;;
  }
}

.displayLines {
  ion-row {
    margin-bottom: 3px;
    border-bottom: 1px solid #666;
  }
}

.card-content-margin {
  padding-top: 20px !important;
}

.more-actions-bar {
  padding-bottom: 5px !important;
  margin-bottom: -4px !important;
}

.no-border {
  --border-width: 0 !important;
}
//
.detail-list {
  //height: 30px !important;
  padding: 0px;
  font-size: 14px !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.no-margin-top-bottom {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.padding-5 {
  padding: 5px !important;
}

.margin-top-bottom-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin-left-right-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.settingsModal {
  --min-width: 95%;
  --min-height: 100%;
  ion-button {
    color: var(--ion-color-secondary) !important;
  }
}

.splash-background {
  background: url('/assets/images/splash.png') no-repeat center fixed !important;
  background-size: cover !important;

  --ion-card-background: none;
}

.fullheight {
  height: 100%;
}
.xc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alignCenter {
  display: table;
  margin: 0 auto;
}

.login-box {
  ion-input {
    border: 1px solid var(--ion-color-primary) !important;
  }


}

.active-item {
  ion-icon {
    color: var(--cloudotdr-secondary);
  }
}

.deploymentName {
  padding: 0 0 10px !important;
  margin: 0;
  line-height: 0;
  font-size: 10px;
  text-transform: uppercase !important;
  font-style: italic;
  //color: var(--cloudotdr-secondary);
}

.loginName {
  font-size: 1em;
  font-weight: bold;
  padding: 10px;
  color: var(--cloudotdr-secondary);
}

ion-backdrop {
  opacity: 0.4;
  background: var(--ion-color-primary);
  z-index: 10;
}

.action-sheet-wrapper {
  z-index: 1000 !important;
}

ion-toast {
  --white-space: pre-wrap;
}


.alert-wrapper {
  --min-width: 400px !important;
  --max-width: 100% !important;
}

.border-bottom-cloudotdr {
  border-bottom: 2px solid !important;
  border-bottom-color:  var(--cloudotdr-secondary) !important;
}

.border-top-cloudotdr {
  border-top: 2px solid !important;
  border-top-color:  var(--cloudotdr-secondary) !important;
}

.cloudotdr-icon {
  color: #fff !important;
}

ion-footer {
  --background: var(--cloudotdr-primary) !important;
}

.footer-margin {
  padding-top: 10px !important;
  margin-top: 5px !important;
  margin-bottom: calc(7px + env(safe-area-inset-top));
}

.cloudotdr-logo-block {
  color: var(--ion-color-primary);
}

.cloudotdr-logo-block-dot {
  color: var(--cloudotdr-secondary);
}

.alert-checkbox-label {
  color: var(--ion-color-primary) !important;
}

.alert-radio-label {
  color: var(--ion-color-primary) !important;
}

.toastFullWidth {
  --max-width: 100%;
  --min-width: 100%;
}

// workaround: full screen action sheet scrolling background on iOS
.action-sheet-group.sc-ion-action-sheet-ios {
  overflow: auto;
}
// workaround: action sheet not honoring safe area margins
.action-sheet-wrapper.sc-ion-action-sheet-ios {
  top: 0;
  height: auto;
}
