.customButton {
  margin: 0 5px 5px 5px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  line-height: 34px;
  text-align: center;
  vertical-align: center;

  .imageHolder {
    position: relative;

    ion-icon {
      font-size: 20px;
      line-height: 34px;
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: 34px;
      color: var(--ion-color-primary-contrast);
    }
  }
}

.amcharts-AmChartsLogo-group {
  visibility: hidden;
}


.zoom-button {
  position: absolute;
  top: 5px;
  right: 2px;
  width: 34px;
  height: 34px;
  background: rgb(82, 118, 176, 0.9);
  color: var(--ion-color-primary-contrast);
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  line-height: 34px;
  text-align: center;

  ion-icon {
    font-size: 20px;
    line-height: 34px;
    position: absolute;
    margin: auto;
    color: var(--ion-color-primary-contrast);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.markerA {
  background: var(--otdr-marker-a) !important;
  color: var(--ion-color-primary-contrast) !important;
}

.markerB {
  background: var(--otdr-marker-b) !important;
  color: var(--ion-color-primary-contrast) !important;
}

.markerRef {
  background: var(--otdr-marker-ref) !important;
  color: var(--otdr-marker-ref-contrast) !important;
}

.save {
  background: rgb(82, 118, 176, 0.9);
  color: var(--ion-color-primary-contrast) !important;
}

.drag {
  background: rgb(82, 118, 176, 0.9);
  color: var(--ion-color-primary-contrast) !important;
}

.buttonDisabled {
  cursor: auto;
  background:  rgb(82, 118, 176, 0.3);
}

.active {
  line-height: 34px;
  background-color: var(--ion-color-success) !important;
}


.chartButton {
  line-height: 24px;

  .markerA{
    cursor: auto !important;
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
  }

  .markerB {
    cursor: auto !important;
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
  }

  .markerRef {
    cursor: auto !important;
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
  }
}

.warning-box {
  width: 100%;
  border: 1px solid var(--ion-color-warning) !important;
  font-size: smaller;
}
